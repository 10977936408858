import _ from "lodash";
import { useCallback } from "react";

export function useDebouncedFn<T extends (...a: any[]) => any>(p: {
  fn: T;
  ms: number;
  deps: any[];
  opts?: { trailing?: boolean; leading?: boolean; maxWait?: number };
}) {
  return useCallback(_.debounce(p.fn, p.ms, p.opts), p.deps);
}
