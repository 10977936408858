import { useEffect, useState } from "react";
import useIsMountedRef from "../../hooks/useIsMountedRef";

type ThenArg<T> = T extends PromiseLike<infer U> ? U : T;

type Result<T> = {
  data: null | ThenArg<T>;
  error: any;
  isFetching: boolean;
  forceRefresh: () => Promise<void>;
};

export function usePromise<T extends Promise<any>>(promFn: () => T, depArr: any[]): Result<T> {
  const isMounted = useIsMountedRef();
  const [isFetching, setIsFetching] = useState(true);

  const doPromiseFetch = async () => {
    setIsFetching(true);
    return promFn().then(
      val => {
        if (isMounted.current) {
          setIsFetching(false);
          setState({
            data: val,
            error: null,
            forceRefresh: doPromiseFetch
          });
        }
      },
      err => {
        if (isMounted.current) {
          setIsFetching(false);
          setState({
            data: null,
            error: err,
            forceRefresh: doPromiseFetch
          });
        }
      }
    );
  };

  const [state, setState] = useState<Omit<Result<T>, "isFetching">>({ data: null, error: null, forceRefresh: doPromiseFetch });

  useEffect(() => {
    doPromiseFetch();
  }, depArr);

  return { ...state, isFetching };
}
